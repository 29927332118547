import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Deload Week`}</em></p>
    <p>{`Skill Practice: Rope Climbs `}<em parentName="p">{`&/or`}</em>{` Ring Muscle Ups`}</p>
    <p>{`then,`}</p>
    <p>{`(Option 1) 20:00 AMRAP of:`}</p>
    <p>{`2-Rope Climbs to 15′`}</p>
    <p>{`10-Calorie Assault Bike`}</p>
    <p>{`20-Situps`}</p>
    <p>{`30-Squats`}</p>
    <p><em parentName="p">{`or`}</em></p>
    <p>{`(Option 2) 20:00 AMRAP of:`}</p>
    <p>{`3-Ring Muscle Ups`}</p>
    <p>{`13-Calorie Assault Bike`}</p>
    <p>{`23-Situps`}</p>
    <p>{`33-Squats`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      